import { useService } from '@Client/runner.hooks/useService';
import { Box, Grid, Typography, Divider } from '@mui/material';
import React from 'react';
import { SharedAngular } from '@Client/@types/sharedAngular';
import CustomButton from '../CustomButton/CustomButton';
import IProcessMap from '@Shared.Angular/@types/processMap';

const ProcessDetails = (props: IProcessMap) => {
  const {
    Id,
    editableByCurrentUser,
    processOwner,
    category,
    processReviewDate,
    description,
    objective,
    background,
    processInput,
    processOutput,
    hideFlowModelOpenButton,
    triggerInput,
    totalActiveTimeHours,
    totalWaitingTimeHours,
    totalCalculatedCost,
    totalTimeHours
  } = props;

  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );
  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const appConfig = useService<AppConfig>('APP_CONFIG');

  const updateViewModelEdit = (): boolean => {
    const hasPermission = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOWMODEL_UPDATE
    );
    return hasPermission || editableByCurrentUser;
  };

  const onEditFlowModelHandler = () => {
    tempModelerUrlService.openModeler(Id, false, false, null);
  };

  const typographyWithEmptyString = (text: string, emptyString: string) => {
    if (text) {
      return <Typography paddingTop={1}>{text}</Typography>;
    } else {
      return (
        <Typography paddingTop={1} fontStyle="italic" color="#a9a9a9">
          {emptyString}
        </Typography>
      );
    }
  };

  const formatDate = (dateString: string) => {
    if (dateString == null) {
      return null;
    }
    const date = new Date(dateString);
    const day = date.toLocaleString('en-US', { day: '2-digit' });
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    return `${day}-${month}-${year}`;
  };

  return (
    <Box className="process-map-view-v2-left-panel-process-detail-container">
      <Grid
        container
        spacing={2}
        className="process-map-view-v2-left-panel-process-detail-grid"
      >
        <Grid item xs={4}>
          <Typography fontWeight={600}>Category</Typography>
          <Typography paddingTop={1}>{category}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Process Owner</Typography>
          {typographyWithEmptyString(
            processOwner,
            'Process Owner not yet defined'
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Process Review Date</Typography>
          {typographyWithEmptyString(
            formatDate(processReviewDate),
            'Process Review Date not yet defined'
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Description</Typography>
          <Typography paddingTop={1}>{description}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Trigger</Typography>
          {typographyWithEmptyString(
            triggerInput,
            'Trigger input not yet defined'
          )}
        </Grid>
        <Grid item xs={4}>
          {appConfig.enableTimeCost && (
            <Box className="process-map-view-v2-left-panel-process-detail-time-cost-box">
              <Typography fontWeight={600}>Time and Cost Analysis</Typography>
              <Box className="process-map-view-v2-left-panel-process-detail-time-cost-row">
                <Typography>Active Time:</Typography>
                <Typography>{totalActiveTimeHours} hrs</Typography>
              </Box>
              <Box className="process-map-view-v2-left-panel-process-detail-time-cost-row">
                <Typography>Waiting Time:</Typography>
                <Typography>{totalWaitingTimeHours} hrs</Typography>
              </Box>
              <Divider className="process-map-view-v2-left-panel-process-detail-custom-divider" />
              <Box className="process-map-view-v2-left-panel-process-detail-time-cost-row">
                <Typography>Total Time:</Typography>
                <Typography>{totalTimeHours} hrs</Typography>
              </Box>
              <Divider className="process-map-view-v2-left-panel-process-detail-custom-divider" />
              <Box className="process-map-view-v2-left-panel-process-detail-time-cost-row">
                <Typography>
                  Total Cost{' '}
                  <i
                    title="Total cost is calculated as: Total Active Time × Average Cost Per Hour"
                    class="fa-light fa-circle-info"
                  ></i>
                  :
                </Typography>
                <Typography>${totalCalculatedCost}</Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} overflow="auto">
          <Typography fontWeight={600}>Objective</Typography>
          {typographyWithEmptyString(objective, 'Objective not yet defined')}
        </Grid>
        <Grid item xs={12} overflow="auto">
          <Typography fontWeight={600}>Background</Typography>
          {typographyWithEmptyString(background, 'Background not yet defined')}
        </Grid>
        <Grid item xs={4} overflow="auto">
          <Typography fontWeight={600}>Process Input</Typography>
          {typographyWithEmptyString(
            processInput,
            'Process Input not yet defined'
          )}
        </Grid>
        <Grid item xs={4} overflow="auto">
          <Typography fontWeight={600}>Process Output</Typography>
          {typographyWithEmptyString(
            processOutput,
            'Process Output not yet defined'
          )}
        </Grid>
        {!hideFlowModelOpenButton && (
          <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
            {updateViewModelEdit() ? (
              <CustomButton
                buttonText="Edit Flow Model"
                onClick={onEditFlowModelHandler}
              />
            ) : (
              <CustomButton
                buttonText="View Flow Model"
                onClick={onEditFlowModelHandler}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProcessDetails;
